<div class="loader-wrapper">
  <div class="svgs-wrapper">
    <div class="loading-svg-wrapper">
      <svg
        class="paw-right"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.4163 13.199C34.0377 12.2993 33.4088 11.6506 32.5971 11.3239L32.5862 11.3198C32.2168 11.1751 31.8235 11.1011 31.4267 11.1016H31.383C29.525 11.1295 27.6282 12.7086 26.6637 15.031C25.9564 16.7294 25.8752 18.5561 26.4468 19.9175C26.8247 20.8178 27.4549 21.4665 28.27 21.7932L28.2789 21.7966C28.6483 21.9412 29.0417 22.0153 29.4384 22.0149C31.3141 22.0149 33.2308 20.4359 34.213 18.0827C34.9115 16.3863 34.9885 14.5611 34.4163 13.199Z"
          fill="black"
        />
        <path
          d="M26.9931 23.2162C25.9216 22.5723 24.9087 21.9632 24.2471 20.8691C22.4218 17.8407 21.3196 16.0127 18.4296 16.0127C15.5396 16.0127 14.4346 17.8407 12.6053 20.8691C11.9423 21.9646 10.9274 22.5743 9.85239 23.2209C8.61987 23.9617 7.34642 24.727 6.79666 26.233C6.58294 26.7759 6.47527 27.3548 6.47949 27.9382C6.47949 30.3903 8.38932 32.3854 10.7357 32.3854C11.9464 32.3854 13.2348 31.9659 14.5983 31.5219C15.9093 31.0949 17.2646 30.6536 18.4364 30.6536C19.6082 30.6536 20.9601 31.0949 22.2663 31.5219C23.6271 31.9632 24.9094 32.3827 26.1235 32.3827C28.4664 32.3827 30.3729 30.3876 30.3729 27.9355C30.3748 27.3516 30.2648 26.7728 30.0489 26.2303C29.4991 24.7229 28.225 23.9569 26.9931 23.2162Z"
          fill="black"
        />
        <path
          d="M11.1987 13.6159C12.0104 14.6343 13.0403 15.195 14.0989 15.195C14.2434 15.1949 14.3877 15.1842 14.5307 15.1629C16.7386 14.8382 18.1143 12.144 17.6628 9.02415C17.4739 7.7125 16.9691 6.49089 16.2461 5.58508C15.4358 4.56878 14.4045 4.00879 13.3466 4.00879C13.2021 4.00879 13.0577 4.01951 12.9148 4.04085C10.7069 4.36552 9.33114 7.05975 9.78268 10.1796C9.97094 11.4892 10.4757 12.7094 11.1987 13.6159Z"
          fill="black"
        />
        <path
          d="M22.3286 15.1623C22.4715 15.1836 22.6158 15.1944 22.7603 15.1944C23.8196 15.1944 24.8489 14.6337 25.6605 13.6153C26.3829 12.7089 26.8849 11.4886 27.0759 10.1776C27.5274 7.05916 26.1516 4.36493 23.9437 4.03889C23.8008 4.01756 23.6565 4.00684 23.512 4.00684C22.4541 4.0082 21.4228 4.56819 20.6124 5.58449C19.8894 6.4903 19.3847 7.71191 19.1964 9.02492C18.7449 12.1434 20.1207 14.8376 22.3286 15.1623Z"
          fill="black"
        />
        <path
          d="M8.57869 21.7966L8.58824 21.7932C9.40197 21.4665 10.0315 20.8185 10.4087 19.9189C10.9803 18.5547 10.8998 16.7301 10.1939 15.0317C9.21644 12.6813 7.30047 11.1016 5.42679 11.1016C5.03001 11.101 4.63666 11.175 4.26725 11.3198L4.2577 11.3232C3.44602 11.6472 2.81646 12.2979 2.43926 13.1976C1.86768 14.5618 1.94816 16.3863 2.65412 18.0847C3.63155 20.4352 5.54752 22.0149 7.4212 22.0149C7.81729 22.0152 8.20992 21.9412 8.57869 21.7966V21.7966Z"
          fill="black"
        />
      </svg>
      <svg
        class="paw-left"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.4163 13.199C34.0377 12.2993 33.4088 11.6506 32.5971 11.3239L32.5862 11.3198C32.2168 11.1751 31.8235 11.1011 31.4267 11.1016H31.383C29.525 11.1295 27.6282 12.7086 26.6637 15.031C25.9564 16.7294 25.8752 18.5561 26.4468 19.9175C26.8247 20.8178 27.4549 21.4665 28.27 21.7932L28.2789 21.7966C28.6483 21.9412 29.0417 22.0153 29.4384 22.0149C31.3141 22.0149 33.2308 20.4359 34.213 18.0827C34.9115 16.3863 34.9885 14.5611 34.4163 13.199Z"
          fill="black"
        />
        <path
          d="M26.9931 23.2162C25.9216 22.5723 24.9087 21.9632 24.2471 20.8691C22.4218 17.8407 21.3196 16.0127 18.4296 16.0127C15.5396 16.0127 14.4346 17.8407 12.6053 20.8691C11.9423 21.9646 10.9274 22.5743 9.85239 23.2209C8.61987 23.9617 7.34642 24.727 6.79666 26.233C6.58294 26.7759 6.47527 27.3548 6.47949 27.9382C6.47949 30.3903 8.38932 32.3854 10.7357 32.3854C11.9464 32.3854 13.2348 31.9659 14.5983 31.5219C15.9093 31.0949 17.2646 30.6536 18.4364 30.6536C19.6082 30.6536 20.9601 31.0949 22.2663 31.5219C23.6271 31.9632 24.9094 32.3827 26.1235 32.3827C28.4664 32.3827 30.3729 30.3876 30.3729 27.9355C30.3748 27.3516 30.2648 26.7728 30.0489 26.2303C29.4991 24.7229 28.225 23.9569 26.9931 23.2162Z"
          fill="black"
        />
        <path
          d="M11.1987 13.6159C12.0104 14.6343 13.0403 15.195 14.0989 15.195C14.2434 15.1949 14.3877 15.1842 14.5307 15.1629C16.7386 14.8382 18.1143 12.144 17.6628 9.02415C17.4739 7.7125 16.9691 6.49089 16.2461 5.58508C15.4358 4.56878 14.4045 4.00879 13.3466 4.00879C13.2021 4.00879 13.0577 4.01951 12.9148 4.04085C10.7069 4.36552 9.33114 7.05975 9.78268 10.1796C9.97094 11.4892 10.4757 12.7094 11.1987 13.6159Z"
          fill="black"
        />
        <path
          d="M22.3286 15.1623C22.4715 15.1836 22.6158 15.1944 22.7603 15.1944C23.8196 15.1944 24.8489 14.6337 25.6605 13.6153C26.3829 12.7089 26.8849 11.4886 27.0759 10.1776C27.5274 7.05916 26.1516 4.36493 23.9437 4.03889C23.8008 4.01756 23.6565 4.00684 23.512 4.00684C22.4541 4.0082 21.4228 4.56819 20.6124 5.58449C19.8894 6.4903 19.3847 7.71191 19.1964 9.02492C18.7449 12.1434 20.1207 14.8376 22.3286 15.1623Z"
          fill="black"
        />
        <path
          d="M8.57869 21.7966L8.58824 21.7932C9.40197 21.4665 10.0315 20.8185 10.4087 19.9189C10.9803 18.5547 10.8998 16.7301 10.1939 15.0317C9.21644 12.6813 7.30047 11.1016 5.42679 11.1016C5.03001 11.101 4.63666 11.175 4.26725 11.3198L4.2577 11.3232C3.44602 11.6472 2.81646 12.2979 2.43926 13.1976C1.86768 14.5618 1.94816 16.3863 2.65412 18.0847C3.63155 20.4352 5.54752 22.0149 7.4212 22.0149C7.81729 22.0152 8.20992 21.9412 8.57869 21.7966V21.7966Z"
          fill="black"
        />
      </svg>
    </div>
    <span
      *ngIf="showCancel"
      class="font-20 font-600 text-white mt-2 p-2 bg-dark rounded"
      role="button"
      (click)="exitLoader()"
      >Cancel</span
    >
  </div>
</div>
